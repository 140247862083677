import React from 'react'

import { Helmet } from 'react-helmet'

import projectStyles from '../style.module.css'
import styles from './home.module.css'

const Home = () => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>ITCrewRocks</title>
        <meta property="og:title" content="ITCrewRocks" />
      </Helmet>
      <div className={styles['Header']}>
        <div className={styles['container01']}>
          <img
            id="logo"
            src="/playground_assets/logo-1000h.png"
            width="120"
            height="120"
            className={styles['image']}
          />
          <div className={styles['container02']}>
            <div className={styles['container03']}>
              <button
                id="servicesButton"
                className={` ${styles['button']} ${projectStyles['button']} `}
              >
                Services
              </button>
              <button
                id="aboutUsButton"
                className={` ${styles['button01']} ${projectStyles['button']} `}
              >
                About Us
              </button>
              <button
                id="certificationsButton"
                className={` ${styles['button02']} ${projectStyles['button']} `}
              >
                Certifications
              </button>
              <button
                id="contactNavbar"
                className={` ${styles['button03']} ${projectStyles['button']} `}
              >
                Contact Us
              </button>
            </div>
          </div>
          <button
            id="hamburgerButtonMenu"
            className={` ${styles['hamburgerButton']} ${projectStyles['button']} `}
          >
            Menu
          </button>
          <div id="hamburgerButton1" className={styles['Hamburger']}>
            <img
              id="hamburgerButton"
              src="/playground_assets/Hamburger_icon.svg"
              width="32"
              height="32"
            />
          </div>
        </div>
      </div>
      <div id="hamburgerMenu" className={styles['hamburgerMenu']}>
        <div className={styles['container04']}>
          <div className={styles['Stay-in-touch']}>
            <div id="linkedinIcon" className={styles['LinkedIn']}>
              <svg
                fill="#ffbf44"
                width="24"
                height="24"
                viewBox="0 0 1024 1024"
                className={styles['icon']}
              >
                <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
              </svg>
              <a
                href="https://www.linkedin.com/company/itcrew-rocks/"
                target="_blank"
                rel="noreferrer noopener"
                className={` ${styles['link']} ${projectStyles['thqLink']} `}
              >
                LinkedIn
              </a>
            </div>
            <div id="gitlabIcon" className={styles['GitLab']}>
              <svg
                fill="#ffbf44"
                width="24"
                height="24"
                viewBox="0 0 1024 1024"
                className={styles['icon01']}
              >
                <path d="M59.429 403.429l452.571 580-496-360c-13.714-10.286-19.429-28-14.286-44l57.714-176zM323.429 403.429h377.143l-188.571 580zM210.286 53.714l113.143 349.714h-264l113.143-349.714c6.286-17.714 31.429-17.714 37.714 0zM964.571 403.429l57.714 176c5.143 16-0.571 33.714-14.286 44l-496 360 452.571-580zM964.571 403.429h-264l113.143-349.714c6.286-17.714 31.429-17.714 37.714 0z"></path>
              </svg>
              <a
                href="https://gitlab.com/itcrew.rocks"
                target="_blank"
                rel="noreferrer noopener"
                className={` ${styles['link01']} ${projectStyles['thqLink']} `}
              >
                GitLab
              </a>
            </div>
          </div>
          <div className={styles['container05']}>
            <button
              id="servicesButton"
              className={` ${styles['button04']} ${projectStyles['button']} `}
            >
              Services
            </button>
            <button
              id="aboutUsButton"
              className={` ${styles['button05']} ${projectStyles['button']} `}
            >
              About Us
            </button>
            <button
              id="certificationsButton"
              className={` ${styles['button06']} ${projectStyles['button']} `}
            >
              Certifications
            </button>
            <button
              id="contactNavbar"
              className={` ${styles['button07']} ${projectStyles['button']} `}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <div className={styles['Intro']}>
        <div className={styles['container06']}></div>
        <div className={styles['container07']}>
          <h1 className={styles['text']}>
            Experienced &amp; Personalized IT Consulting
          </h1>
          <h2 className={styles['text01']}>
            Let us build the infrastructure for your success!
          </h2>
          <div className={styles['container08']}>
            <button
              id="contactHero"
              className={` ${styles['button08']} ${projectStyles['button']} `}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <div id="servicesSection" className={styles['Services']}>
        <div className={styles['Heading']}>
          <h1 className={styles['text02']}>Our Services</h1>
        </div>
        <div className={styles['Section1']}>
          <div className={styles['IT-Support']}>
            <div className={styles['container09']}>
              <svg
                fill="#055471"
                width="70"
                height="70"
                viewBox="0 0 1024 1024"
                className={styles['icon02']}
              >
                <path d="M470 46v338h-300q0-130 87-226t213-112zM170 640v-170h684v170q0 140-101 241t-241 101-241-101-101-241zM554 46q126 16 213 112t87 226h-300v-338z"></path>
              </svg>
              <h3 className={styles['text03']}>IT Support</h3>
            </div>
            <span className={styles['text04']}>
              A cost-effective way of ensuring access to up to date IT Support
              Services. We are available for remote or on-site assistance.
            </span>
          </div>
          <div className={styles['IT-Support1']}>
            <div className={styles['container10']}>
              <svg
                fill="#055471"
                width="70"
                height="70"
                viewBox="0 0 1024 1024"
                className={styles['icon03']}
              >
                <path d="M704 682q44 0 75-31t31-75-31-75-75-31h-22q0-70-50-121t-120-51q-54 0-102 38t-62 92l-6-2q-52 0-90 38t-38 90 38 90 90 38h362zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
              </svg>
              <h3 className={styles['text05']}>Cloud DevOps</h3>
            </div>
            <span className={styles['text06']}>
              We will help you accelerate your business by adapting the
              DevOps/SRE principals using cloud technology.
            </span>
          </div>
          <div className={styles['IT-Support2']}>
            <div className={styles['container11']}>
              <svg
                fill="#055471"
                width="70"
                height="70"
                viewBox="0 0 1024 1024"
                className={styles['icon04']}
              >
                <path d="M896 682v-512h-768v512h768zM896 86q34 0 60 25t26 59v512q0 34-26 60t-60 26h-298v86h84v84h-340v-84h84v-86h-298q-34 0-60-26t-26-60v-512q0-34 26-59t60-25h768z"></path>
              </svg>
              <h3 className={styles['text07']}>
                Windows, Linux and MacOS Administration
              </h3>
            </div>
            <span className={styles['text08']}>
              We will provide you the necessary skills and tools to install,
              configure and troubleshoot your computers and servers.
            </span>
          </div>
        </div>
        <div className={styles['Section11']}>
          <div className={styles['IT-Support3']}>
            <div className={styles['container12']}>
              <svg
                fill="#055471"
                width="70"
                height="70"
                viewBox="0 0 1024 1024"
              >
                <path d="M872 466l2 2-362 448-362-448 2-2-136-168q242-170 496-170t496 170z"></path>
              </svg>
              <h3 className={styles['text09']}>
                On-Premise Network and Servers
              </h3>
            </div>
            <span className={styles['text10']}>
              We will set up, maintain and troubleshoot your business&apos;s
              network and physical servers.
            </span>
          </div>
          <div className={styles['IT-Support4']}>
            <div className={styles['container13']}>
              <svg
                fill="#055471"
                width="70"
                height="70"
                viewBox="0 0 1024 1024"
              >
                <path d="M512 42l384 172v256q0 178-110 325t-274 187q-164-40-274-187t-110-325v-256zM512 512v382q118-38 200-143t98-239h-298zM512 512v-376l-298 132v244h298z"></path>
              </svg>
              <h3 className={styles['text11']}>Systems Security</h3>
            </div>
            <span className={styles['text12']}>
              We will protect you from outside threats. The network can range
              from a single router to a more complex network of multiple office
              locations.
            </span>
          </div>
          <div className={styles['IT-Support5']}>
            <div className={styles['container14']}>
              <svg
                fill="#055471"
                width="70"
                height="70"
                viewBox="0 0 1024 1024"
              >
                <path d="M512 128q160 0 272 112t112 272-112 272-272 112q-54 0-122-23t-112-57l60-60q76 54 174 54 124 0 211-87t87-211-87-211-211-87-211 87-87 211h128l-172 170-170-170h128q0-160 112-272t272-112zM598 512q0 34-26 60t-60 26-60-26-26-60 26-60 60-26 60 26 26 60z"></path>
              </svg>
              <h3 className={styles['text13']}>Data Backup &amp; Restore</h3>
            </div>
            <span className={styles['text14']}>
              We will implement periodical backup &amp; restore procedures
              according to your needs. The data can reside on-premise or in a
              public cloud.
            </span>
          </div>
        </div>
      </div>
      <div id="aboutUsSection" className={styles['About-Us']}>
        <h1 className={styles['text15']}>About Us</h1>
        <div className={styles['container15']}>
          <div className={styles['div']}>
            <a
              href="https://www.linkedin.com/company/itcrew-rocks/"
              target="_blank"
              rel="noreferrer noopener"
              className={styles['link02']}
            >
              <img
                src="/playground_assets/razvan-1x1-1000h.png"
                className={` ${styles['image02']} ${projectStyles['thqLink']} `}
              />
            </a>
            <span className={styles['text16']}>Razvan</span>
            <div className={styles['container16']}>
              <span className={styles['text17']}>6 years</span>
              <span className={styles['text18']}>
                {' '}
                of experience as a System Administrator
              </span>
            </div>
            <span className={styles['text19']}>
              Skillset: Windows OS, Windows Servers, MDT, DPM, SCCM, WSUS,
              Office365, Azure, MacOS and Linux
            </span>
          </div>
          <div className={styles['container17']}>
            <a
              href="https://www.linkedin.com/in/alexandru-vasile-t-68542559"
              target="_blank"
              rel="noreferrer noopener"
              className={styles['link03']}
            >
              <img
                src="/playground_assets/alex-1x1-1000h.png"
                className={` ${styles['image03']} ${projectStyles['thqLink']} `}
              />
            </a>
            <span className={styles['text20']}>Alex</span>
            <div className={styles['container18']}>
              <span className={styles['text21']}>10 years</span>
              <span className={styles['text22']}>
                {' '}
                as a DevOps, System and Network Administrator
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <span className={styles['text23']}>
              Skillset: Linux, AWS, IaC using Terraform/CloudFormation and
              Ansible, Docker and Kubernetes, Kafka
            </span>
          </div>
        </div>
        <div className={styles['container19']}></div>
      </div>
      <div id="certificationsSection" className={styles['Certifications']}>
        <h1 className={styles['text24']}>Certifications</h1>
        <div className={styles['container20']}>
          <div className={styles['CKAD']}>
            <a
              href="https://www.credly.com/badges/bdaa54c1-6c56-403b-b61b-db29f9d81b40/public_url"
              target="_blank"
              rel="noreferrer noopener"
              className={styles['link04']}
            >
              <img
                src="/playground_assets/ckad-certified-kubernetes-application-developer-1000h.png"
                className={` ${styles['image04']} ${projectStyles['thqLink']} `}
              />
            </a>
          </div>
          <div className={styles['DevOpsPro']}>
            <a
              href="https://www.youracclaim.com/badges/2fcb93b7-057b-4d17-a494-70ccab513933/public_url"
              target="_blank"
              rel="noreferrer noopener"
              className={styles['link05']}
            >
              <img
                src="/playground_assets/aws-certified-devops-engineer-professional-1000h.png"
                className={` ${styles['image05']} ${projectStyles['thqLink']} `}
              />
            </a>
          </div>
          <div className={styles['ArchPro']}>
            <a
              href="https://www.youracclaim.com/badges/3a78c7cc-dd24-4b47-a08e-ecc25f454998/linked_in"
              target="_blank"
              rel="noreferrer noopener"
              className={styles['link06']}
            >
              <img
                src="/playground_assets/AWS-SolArchitect-Professional-2020-1000h.png"
                className={` ${styles['image06']} ${projectStyles['thqLink']} `}
              />
            </a>
          </div>
        </div>
        <div className={styles['container21']}>
          <div className={styles['Architect']}>
            <img
              src="/playground_assets/aws-certified-solutions-architect-associate-1000h.png"
              className={styles['image07']}
            />
          </div>
          <a
            href="https://www.youracclaim.com/badges/b97c0c5f-04d4-47cf-8b66-45e69d6bb63e/public_url"
            target="_blank"
            rel="noreferrer noopener"
            className={styles['link07']}
          >
            <div
              className={` ${styles['SysOps']} ${projectStyles['thqLink']} `}
            >
              <img
                src="/playground_assets/aws-certified-sysops-administrator-associate-1000h.png"
                className={` ${styles['image08']} ${projectStyles['thqLink']} `}
              />
            </div>
          </a>
          <a
            href="https://www.youracclaim.com/badges/8f1ebf58-c53d-4f5a-97ce-82e08f81dbd2"
            target="_blank"
            rel="noreferrer noopener"
            className={styles['link08']}
          >
            <div
              className={` ${styles['Developer']} ${projectStyles['thqLink']} `}
            >
              <img
                src="/playground_assets/AWS-Developer-Associate-1000h.png"
                className={` ${styles['image09']} ${projectStyles['thqLink']} `}
              />
            </div>
          </a>
        </div>
        <div className={styles['container22']}>
          <div className={styles['Practitioner']}>
            <a
              href="https://www.youracclaim.com/badges/c8063b2f-4070-4b64-83e2-792a68e29428"
              target="_blank"
              rel="noreferrer noopener"
              className={styles['link09']}
            >
              <img
                src="/playground_assets/AWS-CloudPractitioner-1000h.png"
                className={` ${styles['image10']} ${projectStyles['thqLink']} `}
              />
            </a>
          </div>
          <div className={styles['AzurePractitioner']}>
            <a
              href="https://www.credly.com/badges/72ece62f-fa5c-48e1-8a42-a613fdf367e9/public_url"
              target="_blank"
              rel="noreferrer noopener"
              className={styles['link10']}
            >
              <img
                alt="image"
                src="/playground_assets/azure-fundamentals-600x600-1000h.png"
                className={` ${styles['image11']} ${projectStyles['thqLink']} `}
              />
            </a>
          </div>
          <div className={styles['Linux']}>
            <a
              href="https://www.credly.com/badges/19cc8ec2-9900-44f3-9925-7f0200c9aa62/public_url"
              target="_blank"
              rel="noreferrer noopener"
              className={styles['link11']}
            >
              <img
                src="/playground_assets/LFCS-1000h.png"
                className={` ${styles['image12']} ${projectStyles['thqLink']} `}
              />
            </a>
            <span>LFCS-1600-0936-0100</span>
          </div>
          <div className={styles['Cisco']}>
            <a
              href="https://www.certmetrics.com/cisco/public/verification.aspx"
              target="_blank"
              rel="noreferrer noopener"
              className={styles['link12']}
            >
              <div
                className={` ${styles['container23']} ${projectStyles['thqLink']} `}
              >
                <img
                  src="/playground_assets/CCNA-badge-1000h.jpg"
                  className={styles['image13']}
                />
              </div>
            </a>
            <span className={styles['text26']}>4111441688951TZG</span>
          </div>
        </div>
        <span className={styles['text27']}>
          Let us build the infrastructure for your success!
        </span>
        <button
          id="contactFooter"
          className={` ${styles['button09']} ${projectStyles['button']} `}
        >
          Contact Us
        </button>
      </div>
      <div className={styles['Footer']}>
        <div className={styles['container24']}>
          <div className={styles['Stay-in-touch1']}>
            <h3 className={styles['heading3']}>Stay in touch</h3>
            <div id="linkedinIcon" className={styles['LinkedIn1']}>
              <svg
                fill="#ffbf44"
                width="24"
                height="24"
                viewBox="0 0 1024 1024"
                className={styles['icon08']}
              >
                <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
              </svg>
              <a
                href="https://www.linkedin.com/company/itcrew-rocks/"
                target="_blank"
                rel="noreferrer noopener"
                className={` ${styles['link13']} ${projectStyles['thqLink']} `}
              >
                LinkedIn
              </a>
            </div>
            <div id="gitlabIcon" className={styles['GitLab1']}>
              <svg
                fill="#ffbf44"
                width="24"
                height="24"
                viewBox="0 0 1024 1024"
                className={styles['icon09']}
              >
                <path d="M59.429 403.429l452.571 580-496-360c-13.714-10.286-19.429-28-14.286-44l57.714-176zM323.429 403.429h377.143l-188.571 580zM210.286 53.714l113.143 349.714h-264l113.143-349.714c6.286-17.714 31.429-17.714 37.714 0zM964.571 403.429l57.714 176c5.143 16-0.571 33.714-14.286 44l-496 360 452.571-580zM964.571 403.429h-264l113.143-349.714c6.286-17.714 31.429-17.714 37.714 0z"></path>
              </svg>
              <a
                href="https://gitlab.com/itcrew.rocks"
                target="_blank"
                rel="noreferrer noopener"
                className={` ${styles['link14']} ${projectStyles['thqLink']} `}
              >
                 GitLab   
              </a>
            </div>
          </div>
          <div className={styles['Copyright']}>
            <h3 className={styles['text28']}>Copyright © 2020 itcrew.rocks</h3>
            <h3 className={styles['text29']}>All Rights Reserved.</h3>
            <div className={styles['container25']}>
              <img
                id="madeWithTeleport"
                src="/playground_assets/Made%20with%20teleporthq.svg"
                className={styles['image14']}
              />
            </div>
            <div className={styles['container26']}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
